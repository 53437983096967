<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

       <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">
            <template v-for="(item, key) in list_check_box">                 
                <div :class="item.bg_gray ? 'box-white-fiche bg-gray':'box-white-fiche'" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    
                    <!-- Section Detail Start -->

                    <!--information_general start-->
                    <table class="comparateur2 border-top-gray text-left" v-if="key == 'detail_general_information' && item.show" >
                        <tbody>
                            <tr v-if="data.ma_date">
                                <td class="bold bg-white" width="20%"><strong style="color:#000;">{{$t('Date_AMM')}}</strong></td>
                                <td class="bg-white">{{DDMMMYY(data.ma_date)}}</td>
                            </tr>
                            <!-- <tr v-if="data.nice && !data.nice.date_advice && data.nice.expected_date"> -->
                            <tr v-if="data.nice && data.nice.expected_date">
                                <td class="bold bg-white" width="20%"><strong style="color:#000;">{{$t('Expected date of issue')}}</strong></td>
                                <td class="bg-white">{{DDMMMYY(data.nice.expected_date)}}</td>
                            </tr>
                            <!-- <tr v-if="data.nice && !data.nice.approved && data.nice.submission_date"> -->
                            <tr v-if="data.nice  && data.nice.submission_date">
                                <td class="bold bg-white" width="20%"><strong style="color:#000;">{{$t('Submission date')}}</strong></td>
                                <td class="bg-white">{{DDMMMYY(data.nice.submission_date)}}</td>
                            </tr>
                            <tr v-if="data.nice && data.nice.guidance_number">
                                <td class="bold bg-white" width="20%"><strong style="color:#000;">{{$t('Guidance number')}}</strong></td>
                                <td class="bg-white">{{data.nice.guidance_number}}</td>
                            </tr>
                            <tr v-if="data.nice && data.nice.approved">
                                <td class="bold bg-white" width="20%"><strong style="color:#000;">{{$t('Recommendation')}}</strong></td>
                                <td :class="'bg-white '+nice_class_approved( data.nice.approved)">{{data.nice.approved}}</td>
                            </tr>
                            <tr v-if="data.nice && data.nice.process">
                                <td class="bold bg-white" width="20%"><strong style="color:#000;">{{$t('Process')}}</strong></td>
                                <td class="bg-white">{{data.nice.process}}</td>
                            </tr>

                        </tbody>
                    </table>  
                    <!--information_general end-->

                    <!-- eams start-->                   
                    <div class="content-onglets" v-else-if="key == 'eams'  && item.show">
                        <table class="comparateur2" >
                            <tbody>
                                <tr>
                                    <th>Granted</th>
                                    <th>Expired</th>
                                    <th>EAMS number</th>
                                </tr>
                                <tr>
                                    <td>{{ data.nice && data.nice.date_eams_granted ? DDMMMYY(data.nice.date_eams_granted) : "-" }}</td>
                                    <td>{{ data.nice && data.nice.date_eams_expired ? DDMMMYY(data.nice.date_eams_expired) : "-" }}</td>
                                    <td>
                                        <template v-if="data.nice && data.nice.eams_number">
                                            <span v-if="data.nice && data.nice.eams_pdf">
                                                <LinkUpload :href="'/uploads/Nice/'+$route.params.id+'/eams_pdf.pdf'" class="pdf"  rel="noopener">{{data.nice.eams_number}}</LinkUpload>
                                            </span>
                                            <span v-else-if="data.nice && data.nice.eams_link">
                                                <a :href="data.nice.eams_link" target="_blank" rel="noopener">{{data.nice.eams_number}}</a>
                                            </span>                                     
                                            <span v-else>{{data.nice.eams_number}} </span>                                       
                                            
                                        </template>
                                        <template v-else>
                                            -
                                        </template>
                                    </td>
                                </tr>                                                  
                                <template  v-if="data.nice && data.nice.full_indication">
                                    <tr>
                                        <th colspan="3" align="center">Full indication</th> 
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <span v-html="data.nice.full_indication"></span>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>  
                        </table>
                    </div>
                    <!-- eams end-->                   
                   
                     <!-- detail_indication start-->
                     <Indication  v-else-if="key == 'detail_indication' && item.show"/>
                     <!-- detail_indication end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->

                     <!-- detail_MaSpac start-->
                    <div class="content-onglets"  v-else-if="key == 'detail_MaSpac' && item.show" > 
                        <table class="comparateur2 " >
                            <tbody>
                                <tr colspan="3">
                                    <td class="bold border-top-gray text-left"><strong>{{$t('TRS_AMM')}}</strong></td>
                                    <td class="border-top-gray text-left">{{$t('ceesp_type_amm_'+data.nice.nice_type_amm)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                     <!-- detail_MaSpac end-->

                    <!-- 'sub_detail/conclusion.ctp' -->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />    
                     <!-- detail_Conclusion end-->

                     <!-- appraisal_consultation start-->
                    <template v-else-if="key == 'appraisal_consultation' && item.show" >
                        <div v-for="(items,keys) in data.nice.appraisal_consultation" :key="'appraisal_consultation'+keys" class="content-onglets" >
                            <div class="pull-right" style="text-align: right;">
                               <p v-if="items.Date" style="margin-bottom:0"><strong>Date: </strong> {{DDMMMYY(items.Date)}}</p>
                               <p v-if="items['Type of limitations']" style="margin-bottom:0"><strong>Decision: </strong> {{items['Type of limitations']}}</p>                               
                            </div>
                            <p v-if="txt_appraisal_consultation[keys]"><strong>Commercial arrangements  : </strong>{{txt_appraisal_consultation[keys]}}</p>
                            <p v-if="items['Comments for the commercial arrangements']" :key="'appraisal_consultation_comment'+keys"><strong>Comments of the commercial agreements : </strong><span v-html="items['Comments for the commercial arrangements']"></span></p>
                            <div v-if="items['Rationale and comment']" style="clear: both;"><p v-html="items['Rationale and comment']"> </p></div>
                            <hr v-if="data.nice.appraisal_consultation.length > 1 && keys < data.nice.appraisal_consultation.length - 1">
                        </div>
                    </template>
                     <!-- appraisal_consultation end-->    

                     <!-- appraisal start-->
                    <div class="content-onglets" v-else-if="key == 'appraisal' && item.show">
                        <p v-if="data.nice && data.nice.key_conclusion" v-html="data.nice.key_conclusion"> </p>                        
                        <p v-else> - </p>
                    </div>
                    <!-- appraisal end-->
                    
                    <!-- currentPractice  start-->
                    <div class="content-onglets"  v-else-if="key == 'currentPractice' && item.show">
                       <div v-for="(items, keys) in currentPractice" :key="'currentPractice'+keys" class="box-toggle open">                           
                            <template v-if="items.enable">
                                <a  class="title title-tb"   @click="items.show = !items.show" style="cursor:pointer;">                                        
                                    <strong>{{ $t(items.title) }}</strong>  <em :class="items.show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>

                                <section class="text textJustify" v-if="keys == 'practice_need_of_patients' && items.show">
                                    <p v-html="data.nice.practice_need_of_patients "></p>
                                </section>
                            </template>
                       </div>
                    </div>
                    <!-- currentPractice  End-->

                    <!-- technology  start-->
                    <div class="content-onglets"  v-else-if="key == 'technology' && item.show">                          
                        <div v-for="(items, keys) in technology" :key="'technology'+keys" class="box-toggle">                           
                            <template v-if="items.enable">
                                <a  class="title title-tb"   @click="items.show = !items.show" style="cursor:pointer;">                                          
                                    <strong>{{ $t(items.title) }} </strong>                                
                                    <em :class="items.show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section class="text textJustify" v-if="keys == 'tech_benefits' && items.show">
                                    <p v-if="data.nice.tech_benefits" v-html="data.nice.tech_benefits "></p>
                                    <p v-else>-</p>
                                </section>
                                <section class="text textJustify" v-else-if="keys == 'tech_position_of_treatment' && items.show">
                                    <p v-if="data.nice.tech_position_of_treatment" v-html="data.nice.tech_position_of_treatment "></p>
                                    <p v-else>-</p>
                                </section>
                                <section class="text textJustify" v-else-if="keys == 'tech_adverse_effects' && items.show">
                                    <p v-if="data.nice.tech_adverse_effects " v-html="data.nice.tech_adverse_effects "></p>
                                    <p v-else>-</p>
                                </section>
                            </template>
                        </div>
                    </div>                        
                    <!-- technology  End-->

                    <!-- evidenceClinical  start-->                       
                    <div class="content-onglets"  v-else-if="key == 'evidenceClinical' && item.show" > 
                        <div v-for="(items, keys) in evidenceClinical" :key="'evidenceClinical'+keys" class="box-toggle open">                           
                            <template v-if="items.enable">
                                <a  class="title title-tb"   @click="items.show = !items.show" style="cursor:pointer;">                                          
                                    <strong>{{ $t(items.title) }} </strong>                                
                                    <em :class="items.show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>

                                <div class="text textJustify" v-if="keys == 'clinical_availability_nature_quality' && items.show">
                                    <p v-html="data.nice.clinical_availability_nature_quality"></p>
                                </div>
                                <div class="text textJustify" v-else-if="keys == 'clinical_relevance' && items.show">
                                    <p v-html="data.nice.clinical_relevance"></p>
                                </div>
                                <div class="text textJustify" v-else-if="keys == 'clinical_uncertainties' && items.show">
                                    <p v-html="data.nice.clinical_uncertainties"></p>                                    
                                </div>                                
                                <div class="text textJustify" v-else-if="keys == 'impact_technology' && items.show">
                                    <p v-html="data.nice.impact_technology"></p>
                                </div>        
                                <div class="text textJustify" v-else-if="keys == 'clinical_relevant_subgroups' && items.show">
                                    <p v-html="data.nice.clinical_relevant_subgroups"></p>
                                </div>        
                                <div class="text textJustify" v-else-if="keys == 'clinical_effectiveness_estimate' && items.show">
                                    <p v-html="data.nice.clinical_effectiveness_estimate"></p>
                                </div>               
                                <div class="text textJustify" v-else-if="keys == 'for_reviews' && items.show">
                                    <p v-html="data.nice.for_reviews"></p>
                                </div>                                      
                            </template>
                        </div>
                    </div>
                    <!-- evidenceClinical  End-->

                    <!-- @todo detail_clinical_trials --> 

                    <!-- effectivenessEvidences  start-->
                    <div class="content-onglets"  v-else-if="key == 'effectivenessEvidences' && item.show">
                        <table class="comparateur2" v-if='data.nice && data.nice.nice_effectiveness_evidence'>
                            <thead>
                                <tr>
                                    <th width="10%" class="first" >Model type</th> 
                                    <th width="10%" >ICER comparator</th>
                                    <th width="10%" >ICER type</th>
                                    <th width="10%" >ICER basecase (cost per QALY gained £)</th>
                                    <th width="10%" >ICER lower (cost per QALY gained £)</th>
                                    <th width="10%" >ICER upper (cost per QALY gained £)</th>
                                    <th width="10%" >ICER probability &lt; 30K£ to 50K£</th> 
                                    <th width="30%" class="last">NICE comment on ICER</th> 
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(items, keys) in data.nice.nice_effectiveness_evidence" :key="'nice_effectiveness_evidence'+keys">
                                    <td><span v-html="items.icer_model_type ? items.icer_model_type : '-'"></span></td>
                                    <td><span v-html="items.icer_comparator? items.icer_comparator : '-'"></span></td>
                                    <td><span v-html="items.icer_type? items.icer_type : '-'"></span></td>
                                    <td><span v-html="items.icer_basecase_valeur ? items.icer_basecase_valeur : '-'"></span></td>
                                    <td><span v-html="items.icer_lower_valeur ? items.icer_lower_valeur : '-'"></span></td>
                                    <td><span v-html="items.icer_upper_valeur? items.icer_upper_valeur : '-'"></span></td>
                                    <td><span v-html="items.icer_probability? items.icer_probability : '-'"></span></td>   
                                    <td><span v-html="icer_nice_comment(items.icer_nice_comment) ? items.icer_nice_comment : '-'"></span></td>
                                </tr>
                            </tbody>                         
                        </table>
                        <div class="space"></div>
                        <!-- @todo Full_analysis -->                        
                        <a class="btn btn-mini btn-warning" style="color: #fff;" target="_blank" rel="noopener" :href="FULL_BASE_URL + '/pdf/view_pdf_popup/'+ $route.params.id +'/' + $route.params.agency" > Full analysis </a>
                        <div class="space"></div>
                    </div>

                    <!-- effectivenessEvidences  End-->

                    <!--detail_clinical_trials--> 
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>

                    <!-- 'sub_detail/evaluation_economic.ctp'  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.nice.evaluation_economic" />

                     <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />

                     <!-- evidenceCost start-->
                     <div class="content-onglets"  v-else-if="key == 'evidenceCost' && item.show" >                               
                        <div v-for="(items, keys) in evidenceCost" :key="'evidenceCost'+keys" class="box-toggle open">                            
                            <div v-if="items.enable">
                                <a  class="title title-tb"   @click="items.show = !items.show" style="cursor:pointer;">                                       
                                    <strong>{{ $t(items.title) }} </strong>  <em :class="items.show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>

                                <div class="text textJustify" v-if="keys == 'cost_availability_nature' && items.show">
                                    <p v-html="data.nice.cost_availability_nature"></p>
                                </div>
                                <div class="text textJustify" v-else-if="keys == 'cost_uncertainties' && items.show">
                                    <p v-html="data.nice.cost_uncertainties"></p>
                                </div>
                                <div class="text textJustify" v-else-if="keys == 'cost_incorporation' && items.show">
                                    <p v-html="data.nice.cost_incorporation"></p>
                                </div>
                                <div class="text textJustify" v-else-if="keys == 'cost_potential_significant' && items.show">
                                    <p v-html="data.nice.cost_potential_significant"></p>
                                </div>
                                <div class="text textJustify" v-else-if="keys == 'cost_nhs' && items.show ">
                                    <p v-html="data.nice.cost_nhs"></p>
                                </div>
                                <div class="text textJustify" v-else-if="keys == 'cost_people' && items.show">
                                    <p v-html="data.nice.cost_people"></p>
                                </div>
                                <div class="text textJustify" v-else-if="keys == 'cost_key_drivers' && items.show ">
                                    <p v-html="data.nice.cost_key_drivers"></p>
                                </div>
                                <div class="text textJustify" v-else-if="keys == 'cost_effectiveness_estimate' && items.show">
                                    <p v-html="data.nice.cost_effectiveness_estimate"></p>
                                </div> 
                                <div class="text textJustify" v-else-if="keys == 'impact_beyond_direct_health' && items.show">
                                    <p v-html="data.nice.impact_beyond_direct_health"></p>
                                </div>
                                <div class="text textJustify" v-else-if="keys == 'cost_effectiveness_evidence' && items.show">
                                    <p v-html="data.nice.cost_effectiveness_evidence"></p>
                                </div>
                            </div>
                            
                        </div>
                        
                     </div>
                     <!-- evidenceCost End-->

                     <!-- additionFactor start-->
                    <div class="content-onglets"  v-else-if="key == 'additionFactor' && item.show" > 
                        <div v-for="(items, keys) in additionFactor" :key="'additionFactor'+keys" class="box-toggle open">
                            <div v-if="items.enable">                                
                                <a  class="title title-tb"   @click="items.show = !items.show" style="cursor:pointer;">                                      
                                    <strong>{{ $t(items.title) }} </strong>                                
                                    <em :class="items.show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>                                
                                <div class="text textJustify" v-if="keys == 'patient_access_schemes' && items.show">
                                    <p v-html="data.nice.patient_access_schemes "></p>
                                </div>
                                <div class="text textJustify" v-else-if="keys == 'pricing_arrangements' && items.show">
                                    <p v-html="data.nice.pricing_arrangements "></p>
                                </div>
                                <div class="text textJustify" v-else-if="keys == 'end_of_life_considerations' && items.show">
                                    <p v-html="data.nice.end_of_life_considerations "></p>
                                </div>
                                <div class="text textJustify" v-else-if="keys == 'cancer_drugs_fund' && items.show">
                                    <p v-html="data.nice.cancer_drugs_fund "></p>
                                </div>
                                <div class="text textJustify" v-else-if="keys == 'equalities_considerations' && items.show">
                                    <p v-html="data.nice.equalities_considerations "></p>
                                </div>
                            </div>
                        </div> 
                    </div>
                     <!-- additionFactor End-->

                     <!-- niceSchedule  start-->
                     <div class="content-onglets" v-else-if="key == 'niceSchedule' && item.show" >       
                        <table class="comparateur2 table border-top-gray" >
                            <tbody>  
                                 <tr v-for="(items,keys) in nice_provisional_schedule" :key="'niceSchedule'+keys">
                                    <td width="20%">{{ items.date ? DDMMMYY(items.date) : ''}}</td>
                                    <td><span v-html="items.text"></span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                     <!-- niceSchedule  End-->

                     <!-- niceProjectHistory start-->
                     <div class="content-onglets"   v-else-if="key == 'niceProjectHistory' && item.show" > 
                        <table class="comparateur2 border-top-gray" >
                            <tbody>
                                <tr v-for="(items, keys) in data.nice.nices_history" :key="'nices_history'+keys">
                                    <td width="20%">{{items.date ? DDMMMYY(items.date) : "-" }}</td>
                                    <td class="textJustify">{{ items.note ? items.note : "-"}}</td>
                                </tr>
                            </tbody>
                        </table>
                     </div>
                     <!-- niceProjectHistory End-->

                    <!-- key_documents-->   
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>

                    <!-- Section Detail End -->
                   
                </div>
            </template> 

            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LinkUpload from '../LinkUpload.vue'
import LeftSection from '../LeftSection.vue'
import Conclusion from '../conclusion.vue'
import TreatmentLine from '../treatment_line.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import LinkAgency from '../LinkAgency.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import KeyDocument from '../key_document.vue' 
import Indication from '../Indication.vue'
import {nice_class_approved, DDMMMYY, check_detail_conclusion,key_documents} from '../../../utils'
export default {
    name : 'nice',
    components : {        
        LinkUpload,
        LeftSection,
        Conclusion,
        TreatmentLine,
        EvaluationEconomic,
        EconomicEvaluation,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        Indication
    },  
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_general_information : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false, 'bg_gray':false},
                eams : {'title':'eams', 'show' : true, 'enable' : false, 'orange_text':false, 'bg_gray':false},
                detail_indication : {'title':'Nice_Indication', 'show' : true, 'enable' : false, 'orange_text':false, 'bg_gray':false},
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false , 'bg_gray':false},
                detail_MaSpac : {'title' : 'information generale trs', 'show' : true, 'enable' : false, 'orange_text':false, 'bg_gray':false},
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true , 'bg_gray':false},
                appraisal_consultation : {'title' : 'NICE_appraisal_consultation', 'show' : true, 'enable' : false, 'orange_text':true , 'bg_gray':true},
                appraisal : {'title' : 'NICE_appraisal', 'show' : true, 'enable' : false, 'orange_text':false , 'bg_gray':false},
                currentPractice : {'title' : 'current_pratice', 'show' : true, 'enable' : false, 'orange_text':false, 'bg_gray':false},
                technology : {'title' : 'technology', 'show' : true, 'enable' : false, 'orange_text':false , 'bg_gray':false},
                evidenceClinical : {'title' : 'evidence_clinical', 'show' : true, 'enable' : false, 'orange_text':false , 'bg_gray':false},
                effectivenessEvidences : {'title' : 'Cost effectiveness Evidence', 'show' : true, 'enable' : false, 'orange_text':false , 'bg_gray':false},
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true, 'bg_gray':false},
                detail_EvaluationEconomic : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true , 'bg_gray':false},
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true , 'bg_gray':false},
                evidenceCost : {'title' : 'evidence_cost', 'show' : true, 'enable' : false, 'orange_text':false , 'bg_gray':false},
                additionFactor : {'title' : 'addition_factor', 'show' : true, 'enable' : false, 'orange_text':false , 'bg_gray':false},
                relatedGuidance : {'title' : 'NICE_related_guidance', 'show' : true, 'enable' : false, 'orange_text':false , 'bg_gray':false},
                niceSchedule : {'title' : 'NICE_schedule', 'show' : true, 'enable' : false, 'orange_text':false , 'bg_gray':false},
                niceProjectHistory : {'title' : 'NICE_project_history', 'show' : true, 'enable' : false, 'orange_text':false , 'bg_gray':false},
                commissionDeTransparence : {'title' : 'commissionDeTransparence', 'show' : false, 'enable' : false, 'orange_text':false , 'bg_gray':false},                
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false }, 
            },                

            evidenceCost : {
                cost_availability_nature : {'title':'evidence_cost1','enable':false,'show' : true},
                cost_uncertainties : {'title':'evidence_cost2','enable':false,'show' : true},
                cost_incorporation : {'title':'evidence_cost3','enable':false,'show' : true},
                cost_potential_significant : {'title':'evidence_cost9','enable':false,'show' : true},
                cost_nhs : {'title':'evidence_cost8','enable':false,'show' : true},
                cost_people : {'title':'evidence_cost4','enable':false,'show' : true},
                cost_key_drivers : {'title':'evidence_cost5','enable':false,'show' : true},                        
                cost_effectiveness_estimate : {'title':'evidence_cost6','enable':false,'show' : true},
                impact_beyond_direct_health : {'title':'impact_beyond_direct_health','enable':false,'show' : true},
                cost_effectiveness_evidence : {'title':'evidence_cost7','enable':false,'show' : true},                
            },
            technology : {
                tech_benefits : {'title':'technology1','enable':true,'show' : true},         
                tech_position_of_treatment : {'title':'technology2','enable':true,'show' : true},          
                tech_adverse_effects : {'title':'technology3','enable':true,'show' : true},          

            },
            additionFactor:{
                patient_access_schemes : {'title':'addition_factor1','enable':false,'show' : true},         
                pricing_arrangements : {'title':'addition_factor4','enable':false,'show' : true},         
                end_of_life_considerations : {'title':'addition_factor2','enable':false,'show' : true},         
                cancer_drugs_fund : {'title':'addition_factor5','enable':false,'show' : true},         
                equalities_considerations : {'title':'addition_factor3','enable':false,'show' : true},   
            },
            evidenceClinical:{
                clinical_availability_nature_quality : {'title':'evidence_clinical1','enable':false,'show' : true},   
                clinical_relevance : {'title':'evidence_clinical2','enable':false,'show' : true},   
                clinical_uncertainties : {'title':'evidence_clinical3','enable':false,'show' : true},   
                impact_technology : {'title':'evidence_clinical7','enable':false,'show' : true},   
                clinical_relevant_subgroups : {'title':'evidence_clinical4','enable':false,'show' : true},   
                clinical_effectiveness_estimate : {'title':'evidence_clinical5','enable':false,'show' : true},   
                for_reviews : {'title':'evidence_clinical6','enable':false,'show' : true},   

            },
            currentPractice : {
                practice_need_of_patients : {'title':'current_pratice1','enable':false,'show' : true},   
            },
            txt_appraisal_consultation : {},
            nice_rovisional_schedule : [],            
                  
        }
    },  
    methods : {       
        nice_class_approved,
        DDMMMYY,
        check_detail_conclusion,
        key_documents,
        Provisional_schedule_sort(param){          
            let tmp = []
            let tmp_provisional_schedule = []
            let arr = []
 
            param['schedule_closing']? tmp.push(param['schedule_closing']+ "___"+ this.$t('NICE_schedule_closing')) : ''
            param['schedule_1']? tmp.push(param['schedule_1']+"___"+ this.$t('NICE_schedule_1')) :''
            param['schedule_2']? tmp.push(param['schedule_2']+"___"+ this.$t('NICE_schedule_2')) :''
            param['schedule_3']? tmp.push(param['schedule_3']+"___"+ this.$t('NICE_schedule_3')) :''
            param['schedule_4']? tmp.push(param['schedule_4']+"___"+ this.$t('NICE_schedule_4')) :''
            param['schedule_5']? tmp.push(param['schedule_5']+"___"+ this.$t('NICE_schedule_5')) :''
            param['schedule_final_determination']? tmp.push(param['schedule_final_determination']+ "___"+ this.$t('NICE_schedule_final_determination')) :''
            param['submission_date'] ? tmp.push(param['submission_date']+ "___"+ this.$t('Manufacturer submission')) :''

            tmp.sort()
            tmp.reverse()          

            for (let key in tmp) {
                arr = tmp[key].split('___')
                tmp_provisional_schedule.push({'date': arr[0], 'text' : arr[1]})
            }
            this.nice_provisional_schedule = tmp_provisional_schedule 
        },
        icer_nice_comment(param){
            return param.replace(/(<([^>]+)>)/gi, "");
        },        
        appraisal_consultation_txt(param ){            
                    
            let arr = [
                'Commercial arrangements',
                'Patient access scheme',
                'Simple discount scheme',
                'Complex scheme', 
                'Commercial access agreement'
            ]
            
            for (let key in param.appraisal_consultation){ 
                let tmp_appraisal = []                                                
                for (let key2 in arr){  
                    if (param.appraisal_consultation[key][arr[key2]] && param.appraisal_consultation[key][arr[key2]] == 1){ 
                        tmp_appraisal.push(arr[key2])
                    }                   
                }  
                this.txt_appraisal_consultation[key]  = tmp_appraisal.join(', ')           

            }                      
        },        
    },
    computed : {        
        data(){
            return this.$store.getters['detail/data']
        },
        FULL_BASE_URL(){
            return process.env.VUE_APP_URL_PRISMACCESS
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }               
    },
   created() {       
            // condition to show check box in left section [enable]
            if(this.data && this.data.nice){                    
                            
            if (this.data.nice['date_refusal_ema'] || this.data.nice['ma_date']|| this.data.nice['expected_date']|| 
                this.data.nice['submission_date']|| this.data.nice['guidance_number']|| this.data.nice['approved'] || this.data.nice['process']) {
                this.list_check_box['detail_general_information'].enable = true
            }
            if (this.data.nice['date_eams_granted'] && this.data.nice['date_eams_granted'].replace(/(<([^>]+)>)/gi, "") ||  
                this.data.nice['date_eams_expired'] && this.data.nice['date_eams_expired'].replace(/(<([^>]+)>)/gi, "") ||  
                this.data.nice['full_indication'] && this.data.nice['full_indication'].replace(/(<([^>]+)>)/gi, "")||  
                this.data.nice['eams_number'] && this.data.nice['eams_number'].replace(/(<([^>]+)>)/gi, "")){

                this.list_check_box['eams'].enable = true
            }
            if (this.data['indication_en']){
                this.list_check_box['detail_indication'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }
            if(this.data.nice.nice_type_amm) { 
                this.list_check_box['detail_MaSpac'].enable = true
            }
            if(this.check_detail_conclusion(this.data, this.$i18n.locale) || this.data.nice.comment ) { 
                this.list_check_box['detail_Conclusion'].enable = true
            }
            
            if(this.data.nice.appraisal_consultation) {                  
                this.appraisal_consultation_txt(this.data.nice)
                this.list_check_box['appraisal_consultation'].enable = true   
            }
            if(this.data.nice.key_conclusion) { 
                this.list_check_box['appraisal'].enable = true
            }
            if(this.data.nice.practice_need_of_patients) { 
                this.list_check_box['currentPractice'].enable = true                    
                this.currentPractice['practice_need_of_patients'].enable = true
            }
            if( this.data.nice.tech_benefits && this.data.nice.tech_benefits.replace(/(<([^>]+)>)/gi, "")   ||
            this.data.nice.tech_position_of_treatment && this.data.nice.tech_position_of_treatment.replace(/(<([^>]+)>)/gi, "") ||                
            this.data.nice.tech_adverse_effects && this.data.nice.tech_adverse_effects.replace(/(<([^>]+)>)/gi, "") 
            ) { 
                this.list_check_box['technology'].enable = true
            }
            if(this.data.nice.clinical_availability_nature_quality 
            || this.data.nice.clinical_relevance 
            || this.data.nice.clinical_uncertainties 
            || this.data.nice.clinical_relevant_subgroups
            || this.data.nice.clinical_effectiveness_estimate
            || this.data.nice.for_reviews
            || this.data.nice.impact_technology
            ) { 
                this.list_check_box['evidenceClinical'].enable = true
                if (this.data.nice.clinical_availability_nature_quality) this.evidenceClinical['clinical_availability_nature_quality'].enable = true
                if (this.data.nice.clinical_relevance) this.evidenceClinical['clinical_relevance'].enable = true
                if (this.data.nice.clinical_uncertainties) this.evidenceClinical['clinical_uncertainties'].enable = true
                if (this.data.nice.clinical_relevant_subgroups) this.evidenceClinical['clinical_relevant_subgroups'].enable = true
                if (this.data.nice.clinical_effectiveness_estimate) this.evidenceClinical['clinical_effectiveness_estimate'].enable = true
                if (this.data.nice.for_reviews) this.evidenceClinical['for_reviews'].enable = true
                if (this.data.nice.impact_technology) this.evidenceClinical['impact_technology'].enable = true
                
            }
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }

            if(this.data.nice.nice_effectiveness_evidence.length > 0        
            ) { 
                this.list_check_box['effectivenessEvidences'].enable = true                

            }
            
            if(this.data.nice.evaluation_economic.length > 0 ) { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }
            
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false
            }
                if( this.data.nice['cost_availability_nature'] && this.data.nice['cost_availability_nature'].replace(/(<([^>]+)>)/gi, "")
            || this.data.nice['cost_uncertainties'] && this.data.nice['cost_uncertainties'].replace(/(<([^>]+)>)/gi, "") 
            || this.data.nice['cost_incorporation'] && this.data.nice['cost_incorporation'].replace(/(<([^>]+)>)/gi, "") 
            || this.data.nice['cost_potential_significant'] && this.data.nice['cost_potential_significant'].replace(/(<([^>]+)>)/gi, "")
            || this.data.nice['cost_people'] && this.data.nice['cost_people'].replace(/(<([^>]+)>)/gi, "")
            || this.data.nice['cost_nhs'] && this.data.nice['cost_nhs'].replace(/(<([^>]+)>)/gi, "")
            || this.data.nice['cost_key_drivers'] && this.data.nice['cost_key_drivers'].replace(/(<([^>]+)>)/gi, "")
            || this.data.nice['cost_effectiveness_estimate'] && this.data.nice['cost_effectiveness_estimate'].replace(/(<([^>]+)>)/gi, "") 
            || this.data.nice['impact_beyond_direct_health'] && this.data.nice['impact_beyond_direct_health'].replace(/(<([^>]+)>)/gi, "") 
            || this.data.nice['cost_effectiveness_evidence'] && this.data.nice['cost_effectiveness_evidence'].replace(/(<([^>]+)>)/gi, "") 
            ) { 
                this.list_check_box['evidenceCost'].enable = true

                if (this.data.nice.cost_uncertainties) this.evidenceCost['cost_uncertainties'].enable = true 
                if (this.data.nice.cost_incorporation) this.evidenceCost['cost_incorporation'].enable = true 
                if (this.data.nice.cost_availability_nature) this.evidenceCost['cost_availability_nature'].enable = true 
                if (this.data.nice.cost_potential_significant) this.evidenceCost['cost_potential_significant'].enable = true  
                if (this.data.nice.cost_people) this.evidenceCost['cost_people'].enable = true  
                if (this.data.nice.cost_nhs) this.evidenceCost['cost_nhs'].enable = true  
                if (this.data.nice.cost_key_drivers) this.evidenceCost['cost_key_drivers'].enable = true  
                if (this.data.nice.cost_effectiveness_estimate) this.evidenceCost['cost_effectiveness_estimate'].enable = true 
                if (this.data.nice.impact_beyond_direct_health) this.evidenceCost['impact_beyond_direct_health'].enable = true  
                if (this.data.nice.cost_effectiveness_evidence) this.evidenceCost['cost_effectiveness_evidence'].enable = true  

            }
            if(this.data.nice.patient_access_schemes 
            || this.data.nice.pricing_arrangements 
            || this.data.nice.end_of_life_considerations 
            || this.data.nice.cancer_drugs_fund
            || this.data.nice.equalities_considerations 
            ) { 
                this.list_check_box['additionFactor'].enable = true
                if (this.data.nice.patient_access_schemes) this.additionFactor['patient_access_schemes'].enable = true
                if (this.data.nice.pricing_arrangements) this.additionFactor['pricing_arrangements'].enable = true
                if (this.data.nice.end_of_life_considerations) this.additionFactor['end_of_life_considerations'].enable = true
                if (this.data.nice.cancer_drugs_fund) this.additionFactor['cancer_drugs_fund'].enable = true
                if (this.data.nice.equalities_considerations) this.additionFactor['equalities_considerations'].enable = true
                
            }
            // if(this.data.nice.related_guidance){ //link nice
            //     this.list_check_box['relatedGuidance'].enable = true
            // }
            if(this.data.nice.schedule_closing
            || this.data.nice.schedule_1
            || this.data.nice.schedule_2
            || this.data.nice.schedule_3
            || this.data.nice.schedule_4
            || this.data.nice.schedule_5
            || this.data.nice.schedule_final_determination
            || this.data.nice.submission_date              
            ){  
                this.Provisional_schedule_sort(this.data.nice)                  
                this.list_check_box['niceSchedule'].enable = true
            }
            if( this.data.nice.nices_history.length > 0) { 
                this.list_check_box['niceProjectHistory'].enable = true
            }
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }


            //link
            // if(this.data.nice.nice_trs){ 
            //     this.list_check_box['commissionDeTransparence'].enable = true
            // }

            
            }

   },
}
</script>


<style scoped>


a{
    color: #000;
}

.content-onglets h2 {
    font-size: 12px;
    font-weight: normal;
}

/* b, strong {
    font-weight: bold;
} */

.box-toggle.icon-arrow-updown {
    font-size: 16px;
    right: -14px;
    color: #232426;
    margin-left:5px;
}
.box-toggle a:hover i {
    color: #ff6300;
}
.fa-angle-up {
    color : #ff6300;
}

th:last-child {
    border-right: 1px solid #e6e9ee;
}

table.comparateur tbody tr th, table.comparateur2 tbody tr th {
    padding: 8px 3px;
    font-weight: normal; 
}
table.comparateur2 > tbody > tr > td, table.comparateur2 > tbody > tr > th {
    border-bottom: 1px solid #e6e9ee;
    border-left: 1px solid #e6e9ee;
    border-collapse: collapse;
}
.btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning.focus, .btn-warning:active, .btn-warning.active, .open > .dropdown-toggle.btn-warning {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512;
}
table.comparateur2.text-left tbody tr td, table.comparateur2.text-left thead tr th {
    text-align: left !important;
}
.text-left {
    text-align: left !important;
}
.comparateur2 .orange {color:orange}
.comparateur2 .red {color:red}
.comparateur2 .green {color:green}
.comparateur2 .blue {color:#2caed2}
</style>